import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { NgClass } from '@angular/common'
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, signal, ViewChild } from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { interval } from 'rxjs'
import { AiCreationDialogComponent } from 'src/app/main/dialogs/ai-creation-dialog/ai-creation-dialog.component'
import { IconModule } from 'src/app/ui/icon/icon.module'

export const VIDEO_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?t=\d+)?|(?:https?:\/\/)?(?:www\.)?twitch\.tv\/[a-zA-Z0-9_]+\/clip\/([a-zA-Z0-9_-]+)/

@Component({
  selector: 'app-create-project-bar',
  standalone: true,
  imports: [IconModule, NgxTippyModule, ReactiveFormsModule],
  templateUrl: './create-project-bar.component.html',
  styleUrl: './create-project-bar.component.scss',
})
export class CreateProjectBarComponent implements OnInit {
  constructor(
    private overlay: Overlay,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  @ViewChild('focus')
  protected focusElement?: ElementRef

  protected videoUrl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.pattern(VIDEO_REGEX)],
  })

  protected placeholder = signal('')

  protected selectedVideo = signal<File | null>(null)

  @Output()
  public projectCreated = new EventEmitter<void>()

  ngOnInit(): void {
    let currentPlaceholderIndex = 0
    const placeholderText = ['Paste YouTube URL']
    interval(5 * 1000).subscribe(() => {
      this.placeholder.set(placeholderText[currentPlaceholderIndex] + ' or drop video')
      currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholderText.length
    })
    this.placeholder.set(placeholderText[currentPlaceholderIndex] + ' or drop video')
    currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholderText.length
  }

  protected openUploadDialog(): void {
    this.selectedVideo.set(null)
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'video/*,audio/*'
    input.onchange = () => {
      if (!input.files) return
      this.selectedVideo.set(input.files[0])
      this.openCreationOverlay()
    }

    input.click()
  }

  private overlayRef: OverlayRef | null = null

  protected openCreationOverlay() {
    this.closeOverlay()

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      backdropClass: 'blurred-bg',
    })
    const componentRef = this.overlayRef.attach(new ComponentPortal(AiCreationDialogComponent))
    if (this.selectedVideo()) {
      componentRef.instance.videoFile = this.selectedVideo()
    } else {
      componentRef.instance.videoUrl = this.videoUrl.getRawValue()
    }

    this.overlayRef.backdropClick().subscribe(() => {
      componentRef.instance.closeOverlay()
    })

    componentRef.instance.closeDialog.subscribe(created => {
      if (created) {
        this.projectCreated.emit()
      }

      this.closeOverlay()
    })

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = null
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        componentRef.instance.closeOverlay()
      }
    })
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach()
      this.selectedVideo.set(null)
    }
  }
}
