import { Injectable } from '@angular/core'
import { AccountInformation } from '../../interfaces/account-information'
import { environment } from 'src/app/environments/environment'
import { Observable, ReplaySubject, map, of, tap } from 'rxjs'
import { AccountTokenResponse, isAccountTokenResponse } from '../../interfaces/account-token-response'
import { AuthApiService, UpdateAccountInformationData } from './auth-api.service'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private api: AuthApiService,
    private router: Router,
    private http: HttpClient
  ) {
    this.initialiseAuthService()
  }

  public updateUserInformation() {
    return this.api.getAccountInformation().pipe(
      tap(account => {
        this.setCachedAccountInformation({
          account,
          token: this.getToken() ?? '',
        })
      })
    )
  }

  private persistentUser = new ReplaySubject<AccountInformation | null>(1)

  /**
   * Gets the persistent user. This is the user that is logged in, and is persisted
   * across page refreshes. Used for live updates to the user's information.
   *
   * ```ts
   * authService.retrievePersistentUser().subscribe(user => {
   *  console.log(user)
   * })
   * ```
   */
  public retrievePersistentUser() {
    return this.persistentUser.asObservable()
  }

  private initialiseAuthService(): void {
    const cachedAccountInformation = localStorage.getItem(
      `${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`
    )
    if (cachedAccountInformation) {
      const accountInformation = JSON.parse(cachedAccountInformation) as unknown
      if (!isAccountTokenResponse(accountInformation)) {
        localStorage.removeItem(`${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`)
        console.error(
          "Login information isn't a valid account token response. Someone's been tampering with the local storage. Clearing the login information key..."
        )
        this._cachedAccountInformation = null
        this.persistentUser.next(null)
        this.router.navigate(['/auth/login'])
        return
      }

      this.setCachedAccountInformation(accountInformation)
    }
  }

  private _cachedAccountInformation: AccountInformation | null = null

  /**
   * Returns the cached account information. Useful in getting user information without
   * having to make a request to the server.
   * ```
   * // Returns the cached account information.
   * const { email, profilePicture } = authService.cachedAccountInformation
   * console.log("The user's email is: ", email)
   * ```
   */
  public get cachedAccountInformation(): AccountInformation | null {
    return this._cachedAccountInformation
  }

  /**
   * Logs in a user via email and password, and forcefully sets the cached account information.
   *
   * ```ts
   * const email = 'test@email.com'
   * const password = 'password'
   *
   * authService.loginViaEmail(email, password).subscribe(account => {
   *  console.log(account.id === authService.cachedAccountInformation.id) // true
   * })
   * ```
   *
   * @param email
   * @param password
   */
  public loginViaEmail(email: string, password: string) {
    return this.api.loginViaEmail(email, password).pipe(
      tap(this.setCachedAccountInformation.bind(this)),
      map(response => response.account)
    )
  }

  // public loginViaGoogle(): Observable<AccountTokenResponse> {
  //   return of({
  //     account: {},
  //     token: '',
  //   })
  // }

  private setCachedAccountInformation(accountInformation: AccountTokenResponse): void {
    this._cachedAccountInformation = accountInformation.account

    localStorage.setItem(
      `${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`,
      JSON.stringify(accountInformation)
    )

    this.persistentUser.next(accountInformation.account)
  }

  /**
   * Helper function used to get the desired redirect URL from the URL query parameters. Used in
   * the login and register pages, potentially also among other pages.
   *
   * ```ts
   * // GET /auth/login?then=%2Fdashboard%2Fvideos
   * const redirect = authService.desiredRedirectUrl
   * console.log(redirect) // /dashboard/videos
   * ```
   */
  public get desiredRedirectUrl(): string {
    const params = new URLSearchParams(window.location.search)
    const redirect = params.get('then')
    if (!redirect) return '/'

    // convert it from a URL-encoded string to a normal string
    return decodeURIComponent(redirect)
  }

  /**
   * Helper function used to get the desired redirect URL query parameters from the URL query parameters.
   */
  public get desiredRedirectUrlQueryParams() {
    const params = new URLSearchParams(window.location.search)
    params.delete('then')

    // we need to convert the URLSearchParams object to a normal object. LOL!
    const obj: Record<string, string> = {}

    params.forEach((value, key) => {
      obj[key] = value
    })

    return obj
  }

  /**
   * Logs a user out, and sends them to the login page. If `shouldKeepRedirectInfoInUrl` is true,
   * the user will be redirected to the login page with the `then` query parameter set to the current
   * URL. This is useful for redirecting the user back to the page they were on before logging out.
   * @param shouldKeepRedirectInfoInUrl Whether or not to keep the redirect information in the URL.
   */
  public logout(shouldKeepRedirectInfoInUrl = false): void {
    localStorage.removeItem(`${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`)

    this._cachedAccountInformation = null
    this.persistentUser.next(null)

    if (shouldKeepRedirectInfoInUrl) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          then: window.location.pathname,
          test: 'test',
        },
      })
    } else {
      this.router.navigate(['/auth/login'])
    }
  }

  public createAccount({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string
    lastName: string
    email: string
    password: string
  }) {
    return this.http
      .post<AccountTokenResponse>(`${environment.baseApiUrl}/users/`, {
        firstName,
        lastName,
        email,
        password,
      })
      .pipe(
        tap(this.setCachedAccountInformation.bind(this)),
        map(response => response.account),
        tap(() => {
          this.router.navigate([this.desiredRedirectUrl])
        })
      )
  }

  public sendVerificationEmail() {
    return this.http.get<{ success: boolean }>(`${environment.baseApiUrl}/email-verification/`)
  }

  public verifyEmail(token: string) {
    return this.http
      .post<AccountInformation>(`${environment.baseApiUrl}/email-verification/`, {
        code: token,
      })
      .pipe(
        tap(info => {
          this.setCachedAccountInformation({
            account: info,
            token: this.getToken() ?? '',
          })
        }),
        tap(() => {
          this.router.navigate([this.desiredRedirectUrl])
        })
      )
  }

  public getToken() {
    const cachedAccountInformation = localStorage.getItem(
      `${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`
    )
    if (cachedAccountInformation) {
      const accountInformation = JSON.parse(cachedAccountInformation) as unknown
      if (!isAccountTokenResponse(accountInformation)) {
        localStorage.removeItem(`${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`)
        console.error(
          "Login information isn't a valid account token response. Someone's been tampering with the local storage. Clearing the login information key..."
        )
        this._cachedAccountInformation = null
        this.persistentUser.next(null)
        this.router.navigate(['/auth/login'])
        return null
      }

      return accountInformation.token
    }

    return null
  }

  public redirectToDesiredUrl() {
    this.router.navigate([this.desiredRedirectUrl], {
      queryParams: this.desiredRedirectUrlQueryParams,
    })
  }

  public updateAccountInformation(data: UpdateAccountInformationData) {
    return this.api.updateAccountInformation(data).pipe(
      tap(account => {
        this.setCachedAccountInformation({
          account,
          token: this.getToken() ?? '',
        })
      })
    )
  }
}
