import { animate, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  signal,
} from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { catchError, from, switchMap, tap } from 'rxjs'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { Video } from 'src/app/main/interfaces/video.interface'
import { VideoService } from 'src/app/main/services/video.service'
import { ComponentLayoutPreviewComponent } from 'src/app/ui/component-layout-preview/component-layout-preview.component'
import { DoubleSliderComponent } from 'src/app/ui/double-slider/double-slider.component'
import { StepperComponent } from 'src/app/ui/stepper/stepper.component'
import YoutubePlayer from 'youtube-player'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { ActionSetVideoDataComponent } from './action-set-video-data/action-set-video-data.component'
import { ActionSetVideoSourceComponent } from './action-set-video-source/action-set-video-source.component'
import { ActionSetVideoLibraryComponent } from './action-set-video-library/action-set-video-library.component'
import { LibraryClip } from 'src/app/main/interfaces/clip-library/library-clip.interface'

export const MAX_VIDEO_LENGTH = 300
export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

export type VideoType = 'youtube' | 'file'

@Component({
  selector: 'app-create-video-action-add-video',
  standalone: true,
  imports: [
    StepperComponent,
    ReactiveFormsModule,
    ComponentLayoutPreviewComponent,
    CommonModule,
    DoubleSliderComponent,
    ActionSetVideoDataComponent,
    ActionSetVideoSourceComponent,
    ActionSetVideoLibraryComponent,
  ],
  templateUrl: './create-video-action-add-video.component.html',
  styleUrl: './create-video-action-add-video.component.scss',
  animations: [
    trigger('animateHeight', [
      transition(':enter', [style({ height: 0 }), animate('0.15s', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('0.15s', style({ height: 0 }))]),
    ]),
  ],
})
export class CreateVideoActionAddVideoComponent {
  constructor(private fb: NonNullableFormBuilder) {}

  @Input({ required: true })
  public component!: VideoComponentV0_0_0

  @Input({ required: true })
  public template!: Video

  @Output()
  public componentCreated = new EventEmitter<VideoComponentV0_0_0>()

  @Output()
  public fileAdded = new EventEmitter<{ file: File; componentId: string }>()

  protected currentStep = 0
  private explicitManualEditing = false

  protected videoType = signal<VideoType>('file')
  protected canChangeVideoType = signal(true)

  protected get manualEditing() {
    return !this.component.editorData?.useLibrary || this.explicitManualEditing
  }

  protected getTitle = computed(() => {
    const videoType = this.videoType()
    const canChangeType = this.canChangeVideoType()

    const useLibrary = this.component.editorData?.useLibrary

    if (useLibrary) {
      return 'Select video from library'
    }

    if (videoType === 'youtube' && !canChangeType) {
      return 'Add a YouTube video'
    } else if (videoType === 'file' && !canChangeType) {
      return 'Upload a video'
    }

    return 'Add video'
  })

  protected getSubtitle = computed(() => {
    const videoType = this.videoType()
    const canChangeType = this.canChangeVideoType()

    const useLibrary = this.component.editorData?.useLibrary

    if (useLibrary) {
      return 'Choose an existing video from the library.'
    }

    if (videoType === 'youtube' && !canChangeType) {
      return 'Add a YouTube video to the template.'
    } else if (videoType === 'file' && !canChangeType) {
      return 'Upload a video to the template.'
    }

    return 'Add a video to the template using the options below.'
  })

  protected form = this.fb.group({
    url: [''],
    file: [null as File | null], // not required. could be youtube.
    subclipMode: ['', [Validators.required]], // "auto" | "manual"
    subclipStart: [0], // not required, as AI could handle it.
    subclipEnd: [60], // ^^^
  })

  protected secondForm = this.fb.group({
    clipCount: [1, []],
    clipTheme: [''],
    muted: [false],
    scaleType: ['stretch'],
  })

  protected validityState: 'valid' | 'invalid' | 'pending' | 'waiting' = 'waiting'

  protected isValid() {
    if (this.currentStep === 0) {
      if (this.validityState !== 'valid' && this.form.controls.subclipMode.value === 'auto') return false

      if (this.videoType() === 'youtube' && !this.form.controls.url.value) return false

      if (this.videoType() === 'file' && !this.form.controls.file.value) return false

      return this.form.valid
    }

    return this.secondForm.valid
  }

  protected nextStep() {
    if (!this.isValid()) return

    if (this.currentStep === 0) {
      this.currentStep = 1
      return
    }

    const newComponent: VideoComponentV0_0_0 = {
      ...this.component,
    }

    const { url, subclipEnd, subclipStart, subclipMode, file } = this.form.getRawValue()
    const { clipTheme, clipCount, muted, scaleType } = this.secondForm.getRawValue()

    newComponent.duration = subclipEnd - subclipStart

    newComponent.data = {
      url,
      startTime: 0,
      subclipStart,
      subclipEnd,
      scaleType,
      mute: muted,
      requiresAiSubclip: subclipMode === 'auto',
      aiClipTheme: clipTheme,
      aiClipCount: 1,
    }

    if (file) {
      this.fileAdded.emit({ file, componentId: newComponent.id })
    }
    this.componentCreated.emit(newComponent)
  }

  protected patchSecondFormValue(
    value: Partial<{ clipCount: number; clipTheme: string; muted: boolean; scaleType: string }>
  ) {
    this.secondForm.patchValue(value)
  }

  protected patchFirstFormValue(
    value: Partial<{ url: string; subclipMode: 'auto' | 'manual'; subclipStart: number; subclipEnd: number }>
  ) {
    this.form.patchValue(value)
  }

  protected onVideoLibraryClipSelected(clipSelected: LibraryClip) {
    this.form.patchValue({
      url: clipSelected.video,
      subclipMode: 'manual',
      // The subclips are determined just before we send the data to the server.
      // They are set to fit the duration of the other clips.
      // If no other clips are present, we fail.
      subclipStart: 0,
      subclipEnd: 0,
    })
    this.secondForm.patchValue({
      muted: true,
      scaleType: 'stretch',
    })

    this.currentStep = 1
    this.nextStep()
  }

  public setVideoType(type: 'youtube' | 'file', restrict = false) {
    this.videoType.set(type)
    this.canChangeVideoType.set(!restrict)
    this.form.reset()
    this.secondForm.reset()
    this.currentStep = 0
  }
}
