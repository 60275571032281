import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { environment } from 'src/app/environments/environment'
import { OrganisationService } from '../services/organisation/organisation.service'
import { RenderStatus } from '../interfaces/render'

// export type RenderEvent = 'render' | 'render.status_change'
export type ProjectEvent = 'project.video.updated'

export type RenderStatusChangeEvent = {
  event: 'render.status_change'
  data: {
    status: RenderStatus
    uuid: string
    video_uuid: string
  }
}

export type RenderEvent = {
  event: 'render'
  data: {
    uuid: string
    video_uuid: string
    progress: number
    status: RenderStatus
  }
}

export type ProjectVideoUpdatedEvent = {
  event: 'project.video.updated'
  data: {
    project_uuid: string
    clip_count: number
  }
}

export type Event = RenderStatusChangeEvent | RenderEvent | ProjectVideoUpdatedEvent

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  constructor(
    private authService: AuthService,
    private organisationService: OrganisationService
  ) {
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      if (organisation) {
        this.initialiseEventsSocket(organisation.uuid)
      }
    })
  }

  private socketSubject = new BehaviorSubject<Event | null>(null)

  public getEventsSocketAsObservable() {
    return this.socketSubject.asObservable()
  }

  public initialiseEventsSocket(organisationUuid: string) {
    const token = this.authService.getToken()
    if (!token) return

    const socket = new WebSocket(`${environment.baseWsUrl}/ws/events/?token=${token}&organisation=${organisationUuid}`)
    socket.onmessage = event => {
      this.socketSubject.next(JSON.parse(event.data))
    }

    socket.onclose = () => {
      this.socketSubject.complete()
    }

    socket.onerror = error => {
      this.socketSubject.error(error)
    }
  }
}
