import { Injectable } from '@angular/core'
import { EventsApiService } from './events-api.service'

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private eventsApi: EventsApiService) {}

  public getEventsSocket() {
    return this.eventsApi.getEventsSocketAsObservable()
  }
}
