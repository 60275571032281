<div class="mt-8 gap-2 sm:gap-8 flex flex-col">
  @if (error) { @if (!loading) {
  <div class="grow items-center justify-center flex flex-col">
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="h-12 sm:h-[5.25rem] aspect-square text-neutral-200 mb-2">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M5.31171 10.7615C8.23007 5.58716 9.68925 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.31034 17.856 2.52291 15.7061 4.94805 11.4063L5.31171 10.7615Z"
          stroke="currentColor"
          stroke-width="1.5"></path>
        <path d="M12 8V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
        <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
      </g>
    </svg>
    <div class="text-neutral-700 text-sm sm:text-base font-medium mb-1">An error occurred</div>
  </div>
  } } @else if (loading) { @for (item of [1, 2, 3]; track $index) {
  <div class="flex items-center gap-4 p-2">
    <div class="h-16 sm:h-20 md:h-[5.55rem] aspect-video bg-neutral-100 rounded-lg shrink-0 animate-pulse"></div>
    <div class="grow">
      <div class="h-5 max-w-[11rem] bg-neutral-100 animate-pulse rounded"></div>
      <div class="flex items-center gap-2 mt-2">
        <ng-icon name="heroCalendar" class="text-neutral-500 text-sm h-4 w-4 mb-0.5" aria-hidden="true" />
        <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
      </div>
    </div>
  </div>
  } } @else { @if (recentProjects.length > 0) { @for (project of recentProjects; track project.uuid) {
  <div
    [routerLink]="['/video/projects/', project.uuid]"
    class="flex items-center gap-2 sm:gap-4 block rounded-lg hover:bg-neutral-50 transition-colors cursor-pointer p-2">
    <button
      class="h-16 sm:h-20 md:h-[5.55rem] aspect-video bg-neutral-100 rounded-lg flex items-center justify-center shrink-0 default-ring overflow-hidden">
      @if (project.image) {
      <img [src]="project.image" class="h-full select-none" />
      } @else {
      <img [src]="project.projectMediaType === 'audio' ? 'assets/img/svg/audio.svg' : 'assets/img/svg/no-image.svg'" class="h-6 sm:h-full select-none max-h-[2rem]" />
      }
    </button>
    <div class="grow text-left truncate">
      <span class="truncate font-semibold h-5 text-darker">
        {{ project.name }}
      </span>
      <div class="flex gap-2 items-center">
        <div class="flex items-center gap-1 mt-1 truncate">
          <ng-icon name="heroCalendar" class="text-neutral-500 text-sm h-4 w-4 mb-0.5" aria-hidden="true" />
          <span class="text-sm text-neutral-600 font-light truncate">{{ getDateString(project.created) }}</span>
        </div>
        <span class="text-neutral-300">•</span>
        <div class="flex items-center gap-1 mt-1 truncate">
          <ng-icon name="heroVideoCamera" class="text-neutral-500 text-sm h-4 w-4" aria-hidden="true" />
          <span class="text-sm text-neutral-600 font-light truncate">{{ project.videos.length }} clips</span>
        </div>
      </div>
    </div>
  </div>
  } } @else {
  <div class="grow items-center justify-center flex flex-col my-8">
    <svg
      class="h-[5.25rem] aspect-square text-neutral-200 mb-2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="currentColor"
      viewBox="0 0 520 520">
      <g>
        <path
          d="M505.29,157.622c-9.005-5.568-20.585-6.075-30.037-1.342L397,195.244v-42.185c0-16.862-13.256-30.136-30.118-30.136   H183.734l-68.365-80.99c-10.883-12.866-30.131-14.591-43.027-3.685C59.476,49.14,57.866,68.36,68.764,81.233l335.867,396.909   c6.038,7.134,14.641,10.797,23.318,10.797c6.962,0,13.97-2.377,19.71-7.23c12.866-10.891,14.276-30.164,3.378-43.038L397,375.045   v-19.903l78.136,38.964c4.309,2.154,9.098,3.22,13.764,3.22c5.576,0,11.435-1.528,16.34-4.562   c8.99-5.561,14.76-15.386,14.76-25.971v-183.2C520,173.007,514.28,163.183,505.29,157.622z" />
        <path
          d="M0,153.059v244.267c0,16.862,14.138,30.597,31,30.597h260.756L29.879,122.647C13.443,123.128,0,136.499,0,153.059z" />
      </g>
    </svg>
    <div class="text-neutral-400 font-medium mb-1">No projects found</div>
    <!-- <a
      routerLink="/video/projects/"
      fragment="create-project"
      class="text-brand hover:text-mid transition-colors text-sm font-light">
      Create project
    </a> -->
  </div>
  } }
</div>
@if (showPagination()) {
<div class="h-12 flex justify-center items-center gap-4 relative">
  <button
    class="flex items-center justify-center p-2 bg-neutral-100 text-neutral-800 rounded-full"
    [ngClass]="{ ' opacity-50': !response?.previous }"
    [disabled]="!response?.previous"
    (click)="previousPage()">
    <ng-icon name="heroChevronLeft" class="h-4 w-4" />
  </button>
  <button
    class="flex items-center justify-center p-2 bg-neutral-100 text-neutral-800 rounded-full"
    [ngClass]="{ ' opacity-50': !response?.next }"
    [disabled]="!response?.next"
    (click)="nextPage()">
    <ng-icon name="heroChevronRight" class="h-4 w-4" />
  </button>
</div>

}
